import React from 'react'
import './Footer.css'
import { FaFacebook } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'

const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Programmer KAMIL </a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact Me</a></li>
      </ul>


      <div className="footer__socials">
        <a href='https://instagram.com'><FaFacebook/></a>
        <a href='https://facebook.com'><FiInstagram/></a>
        <a href='https://twitter.com'><IoLogoTwitter/></a>
      </div>


      <div className="footer__copyright">
        <small>&copy;PROGRAMMER KAMIL. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer
