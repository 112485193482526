import React from 'react'
 import Header from './components/Header/Header'
 import Nav from './components/nav/Nav'
 import About from './components/About/About'
 import Experience from './components/Experience/Experience'
 import Portfolio from './components/portfolio/Portfolio'
 import Testimo from './components/Testimo/Testimo'
 import Footer from './components/Footer/Footer'
import Contact from './components/Contact/Contact'
 

const App = () => {
  return (
    <>
       <Header/>
       <Nav/>
       <About/>
       <Experience/>
       <Portfolio/>
       <Testimo/>
       <Contact/>
       <Footer/>
    </>
  )
}

export default App
